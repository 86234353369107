/* @import '../fonts/stylesheet.css'; */
@tailwind base;
@tailwind components;
@tailwind utilities;

iframe#webpack-dev-server-client-overlay {
    display: none !important;
}

@layer components {
    .auth_wrapper {
        @apply h-screen;
    }
    .header_sidebar_scroll,
    .p-dropdown-items-wrapper {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .header_sidebar_scroll::-webkit-scrollbar,
    .p-dropdown-items-wrapper::-webkit-scrollbar {
        display: none;
    }
    .header_menu_radio [type='radio']:checked {
        background-size: 18px !important;
    }
    .header_icons_border {
        @apply border border-borderGray shadow-InputAndButton;
    }
    .header_icons {
        @apply header_icons_border justify-center w-8 h-8 flex items-center rounded-lg;
    }
    .header_notification_menu {
        @apply p-3 flex gap-2;
    }
    .header_notification_menu_profile {
        @apply flex justify-between items-center w-full;
    }
    .header_notification_menu_profile_username {
        @apply text-sm font-medium text-mainTextColor;
    }
    .header_notification_menu_profile_date {
        @apply text-gray-600 text-xs font-normal truncate  max-w-56;
    }
    .header_notification_menu_view_button {
        @apply flex items-center h-[18px] gap-[6px] text-xs leading-[18px] font-semibold text-blue-700 cursor-pointer;
    }
    .fl-field-title-label {
        @apply font-medium mb-[6px] block text-xs leading-[18px] text-labelTxt capitalize;
    }
    .fl-input-field {
        @apply w-full rounded-lg flex items-center h-9 border border-borderGray;
    }
    .view-table-title {
        @apply text-gray-900 text-base font-semibold capitalize;
    }
    .view-table-subtitle {
        @apply text-gray-600 text-xs font-normal mt-1;
    }
    .border-hover-custom:hover,
    .border-hover-custom:focus,
    .border-hover-custom:focus-visible .border-hover-custom:focus-within .border-hover-custom:visited .border-hover-custom:active {
        @apply text-gray-900 !border !border-[#98a7c3] !outline-none !shadow !ring-0 !ring-transparent;
    }

    .fl-input-field.fl-phone-field .fl-flag {
        @apply flex items-center pr-[5px]  pl-[10px] grow-0 shrink-0 h-9 rounded-md box-border cursor-pointer border border-transparent;
    }
    .fl-input-field.fl-phone-field .fl-flag {
        @apply outline-0 ring-0;
    }
    .fl-input-field.fl-phone-field .fl-flag .icon-angel {
        @apply ml-2 grow-0 shrink-0 basis-4;
    }
    .fl-dropdown-shadow {
        box-shadow: 0px 5.33333px 16px 0px rgba(146, 140, 151, 0.25);
    }

    .btn_primary {
        @apply text-[14px] leading-5 font-semibold bg-primary text-white -tracking-[0.16px] border border-primarybtn  w-full rounded-lg hover:bg-blue-800 transition-all ease-in-out delay-75 shadow-InputAndButton;
    }
    .btn_disable {
        @apply text-[14px] leading-5 bg-disable  border-btnborder  border text-disable -tracking-[0.16px] font-bold w-full rounded-xl transition-all ease-in-out delay-75 shadow-InputAndButton;
    }
    .btn_outline_primary {
        @apply text-primary bg-transparent border border-primary rounded-full py-[9px] px-[9px] text-center text-base font-medium w-full  hover:bg-primary hover:text-white focus:bg-primary focus:text-white;
    }
    .btn_danger {
        @apply text-base bg-dangerText text-white -tracking-[0.16px] font-bold w-full rounded-full hover:bg-red-800 transition-all ease-in-out delay-75;
    }
    .btn_outline_danger {
        @apply text-base bg-transparent text-dangerText border border-dangerText  -tracking-[0.16px] font-bold w-full rounded-full hover:bg-dangerText hover:text-white transition-all ease-in-out delay-75;
    }
    .btn_outline_black {
        @apply rounded-full bg-transparent border-black  border-2 text-black py-[14px] h-[50px] px-10 flex items-center gap-3 text-sm hover:bg-black hover:text-white transition-all ease-in-out delay-75;
    }
    .form_input_control {
        @apply text-xs pt-[6px] pb-[7px] leading-[20px] px-3 rounded-lg placeholder:text-placeholderTxt placeholder:text-xs placeholder:leading-[18px] border-none focus:ring-0 text-gray-900 placeholder:font-medium font-medium focus:border-gray-300 focus:ring-offset-0 focus-visible:outline-none w-full shadow-InputAndButton;
    }
    .datepicker_control {
        @apply text-sm pt-[6px] pb-[7px] leading-[20px] px-3 rounded-lg placeholder:text-placeholderTxt placeholder:text-xs placeholder:leading-[18px] border-none focus:ring-0 text-gray-900 placeholder:font-medium font-medium focus:border-gray-300 focus:ring-offset-0 focus-visible:outline-none w-full;
    }
    .form_input_control:read-only,
    .form_input_control:disabled {
        @apply focus:border-inputBg cursor-not-allowed;
    }
    .table-searchInput {
        @apply xxl:w-[25rem] xlm:w-[19rem] sm:w-56 w-full;
    }

    .form_input_control.form_password_control {
        @apply pe-14;
    }

    .is_invalid_image {
        @apply border-2 border-dashed border-dangerText bg-dangerBgLight !important;
    }
    .upload-image-block {
        @apply h-[180px] max-w-[630px] mx-auto rounded-3xl bg-inputBg border border-inputBg flex justify-center items-center overflow-hidden relative;
    }

    .upload-image-block.is-invalid {
        @apply bg-inputBg !important;
    }

    /* steper css start */
    .stepper_content {
        @apply flex items-center justify-start;
    }
    .stepper_li {
        @apply relative flex items-center mt-9 first:mt-0;
    }
    .stepper_li:last-child .step__round {
        @apply before:hidden;
    }
    .fwpo__steper {
        @apply items-center justify-between;
    }

    .step__round {
        @apply flex items-center justify-center h-[48px] w-[48px] rounded-[10px] relative text-gray-400 bg-gray-50 border border-btnborder before:absolute before:last:w-0 before:top-[53px] before:left-1/2 before:rounded-lg before:w-[2px] before:opacity-60 before:h-6 before:bg-borderGray;
    }

    .step__round svg {
        @apply opacity-60;
    }

    .stepper_subtext {
        @apply text-sm text-secondaryTxtColor opacity-60 font-normal;
    }

    .steper_text {
        @apply font-semibold text-base text-gray-700 opacity-60;
    }

    .border_start {
        @apply w-full border-t border-gray-300;
    }

    .step_completed .stepper_subtext {
        @apply opacity-100;
    }
    .step_completed .step__round {
        @apply bg-white  text-white before:opacity-100;
    }
    .step_completed .step__round svg {
        @apply opacity-100;
    }

    .step_completed .step__round {
        @apply bg-white  text-white;
    }
    .step_completed .steper_text {
        @apply text-gray-700 opacity-100;
    }
    .step_completed .border_start {
        @apply border-blue-500;
    }

    .step_inprogress .stepper_subtext {
        @apply opacity-100;
    }
    .step_inprogress .step__round {
        @apply bg-white before:opacity-100;
    }
    .step_inprogress .step__round svg {
        @apply opacity-100;
    }
    .step_inprogress .step__round {
        @apply bg-white;
    }
    .step_inprogress .steper_text {
        @apply text-gray-700 opacity-100;
    }
    /* steper css end */
    .fwpo_heading_title {
        @apply text-3xl text-gray-700 leading-[38px]  font-semibold;
    }
    .fl-custom-checkbox,
    .fl-custom-checkbox-bg {
        -webkit-appearance: none;
        appearance: none;
        @apply w-[22px] h-[22px] mt-1 border-2 border-inputBg rounded-sm shadow-none  focus:border-inputBg focus:shadow-none focus:ring-0 ring-offset-0 focus:ring-offset-0 focus:outline-none;
    }

    [type='checkbox']:focus,
    [type='radio']:focus {
        outline: none !important;
        outline-offset: none !important;
        outline-offset: none !important;
        --tw-ring-inset: var(--tw-empty);
        --tw-ring-offset-width: none !important;
        --tw-ring-offset-color: none !important;
        --tw-ring-color: none !important;
        --tw-ring-offset-shadow: none !important;
        --tw-ring-shadow: none !important;
        --tw-shadow: none !important;
        --tw-shadow-colored: none !important;
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow) !important;
    }

    [type='checkbox']:checked {
        background-image: url('data:image/svg+xml,%3csvg aria-hidden=%27true%27 xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 16 12%27%3e %3cpath stroke=%27white%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M1 5.917 5.724 10.5 15 1.5%27/%3e %3c/svg%3e');
        background-repeat: no-repeat;
        background-size: 10px !important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    [type='checkbox'] {
        border-radius: 4px !important;
    }

    .fl-custom-checkbox:checked:checked,
    .fl-custom-checkbox:checked {
        @apply checked:bg-white checked:border-primary checked:outline-none checked:shadow-none;
        background-image: url(../img/icons/checkbox-checked.svg) !important;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    .fl-custom-checkbox-bg:checked:checked,
    .fl-custom-checkbox-bg:checked {
        @apply checked:bg-primary checked:border-primary checked:outline-none checked:shadow-none;
        background-image: url(../img/icons/checkbox-checked-white.svg) !important;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover !important;
    }
    /*-- header_Bar --*/
    .header_Bar {
        @apply flex fixed w-full left-0 top-0 bg-white border-b px-7 py-4;
        box-shadow: 0px 1px 0px 0px rgba(219, 219, 219, 0.4);
    }
    .sidebar_Nav {
        @apply bg-sidebarBg fixed top-0 left-0 bottom-0 pt-6 z-0 w-[220px] lg:w-[240px] xxl:w-[260px];
    }
    .sidebar_Nav_Wrap {
        @apply w-full px-3 h-full  overflow-y-auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .sidebar-brand-dropdown {
        @apply rounded-xl bg-white p-2 border border-borderSecondary hover:bg-sidebarBg;
    }

    .sidebar_Nav_Wrap::-webkit-scrollbar {
        display: none;
    }

    .dashboard_content_wrapper {
        @apply pt-0  bg-white border-t border-l border-borderSecondary rounded-tl-3xl;
    }
    .title_text_page {
        @apply text-gray-900 text-[22px] font-semibold leading-6;
    }
    .subtitle_text_page {
        @apply self-stretch text-[#475467] text-sm font-normal leading-normal mt-1;
    }
    .table-title {
        @apply text-gray-900 text-base font-semibold capitalize;
    }
    .table-subtitle {
        @apply text-[#475467] text-xs font-normal mt-1;
    }
    .sideBarHeading {
        @apply text-xs font-normal mb-1 text-gray-600;
    }
    .sideBarSubHeading {
        @apply text-mainTextColor text-xs font-medium;
    }
    .card_heading {
        @apply text-gray-600 text-xs font-normal mb-1.5;
    }
    .card_sub_heading {
        @apply text-xs font-medium text-gray-600 truncate;
    }
    .sideBoxTitel {
        @apply text-gray-600 text-xs font-normal mb-1.5;
    }
    .sideBoxSubTitel {
        @apply text-gray-900 text-xs font-medium;
    }
    .dashboard_content {
        @apply flex flex-col h-[inherit] overflow-y-auto overflow-x-hidden;
    }
    .dashboard_content .inner-page-wrape {
        @apply flex flex-col flex-1;
    }
    .userCounter {
        @apply pl-[4px] pr-[6px] py-[2px] leading-[14px] text-nowrap h-[20px] text-[10px] text-gray-700 font-medium  border inline-flex  items-center justify-start rounded-md border-gray-300 shadow text-center capitalize min-w-fit;
    }
    .side-spaching {
        @apply xxl:px-6 px-5;
    }
    .day-checkbox {
        @apply w-full max-w-[140px] xl:max-w-[170px] grow-0 shrink-0 basis-[170px] cursor-pointer;
    }
    .day-checkbox .day-check-box {
        @apply hidden;
    }
    .opening-hours-block {
        @apply w-full flex flex-1  flex-col gap-5 p-8 border border-lineLightColor rounded-md;
    }
    .opening-hours-row {
        @apply flex items-start gap-5 justify-between py-3 last:pt-3 last:border-0 last:pb-0  border-b border-borderSecondary;
    }
    .opening-hours-row .from-label {
        @apply pr-5;
    }
    .opening-hours-row .to-label {
        @apply px-5;
    }
    .day-checkbox .day-checkbox-label {
        @apply h-10 relative flex items-center text-xs leading-[18px] font-semibold;
    }
    .opening-hour-right {
        @apply flex items-center;
    }
    .opening-hour-right .label-from {
        @apply pr-3 xl:pr-5 text-xs leading-[18px] font-semibold text-gray-700;
    }
    .opening-hour-right .label-to {
        @apply px-3 xl:px-5;
    }
    .time-input-block {
        @apply flex mr-2 w-[140px] h-[50px] border border-lineColor rounded-xl overflow-hidden;
    }
    .time-input-block .input-control {
        @apply w-1/2 h-full text-center bg-transparent p-3 text-[22px] outline-0 focus:shadow-none focus:ring-0  focus:outline-0 active:shadow-none active:ring-0 active:outline-0 focus-visible:outline-0 focus-visible:shadow-none focus-visible:ring-0;
    }
    .time-input-block.is-invalid {
        @apply border border-dangerText bg-dangerBgLight !important;
    }
    .time-input-block .input-control:focus-visible {
        outline: 0 !important;
    }

    .custom-square-radio-block {
        @apply rounded-lg bg-inputBg p-1 h-9 w-[72px] flex items-center justify-center relative z-0;
    }
    .custom-square-radio-block .custome-label {
        @apply w-[50%] h-[100%] flex items-center justify-center cursor-pointer text-secondaryTxtColor z-[2];
    }
    .custom-square-radio-block .switch-base {
        @apply absolute w-[calc(50%_-_4px)] h-[calc(100%_-_8px)] rounded-lg bg-blue-50 z-0 transition-all translate-x-1 left-0 top-1;
    }
    .custom-square-radio-block .custom-radio:checked + .custome-label {
        @apply text-mainTextColor relative;
    }
    .custom-square-radio-block .radio-first:checked ~ .switch-base {
        @apply translate-x-1;
    }
    .custom-square-radio-block .radio-second:checked ~ .switch-base {
        @apply translate-x-[calc(100%_+_4px)];
    }

    /* .translate-am {
        transform: translateX(1rem);
    }

    .translate-pm {
        transform: translateX(calc(100% + 4px));
    } */

    /* opening hours small */
    .opening-hours-small.opening-hours-block {
        @apply px-0 py-4 gap-0 rounded-3xl;
    }
    .opening-hours-small .opening-hours-row {
        @apply px-6 gap-0;
    }
    .opening-hours-small .opening-hours-row:not(:last-child) {
        @apply mb-4 pb-4;
    }
    .opening-hours-small .opening-hours-row .day-checkbox {
        @apply mr-4 w-full max-w-[115px] grow-0 shrink-0 basis-[115px];
    }
    .opening-hours-small .opening-hours-row .day-checkbox .day-checkbox-label {
        @apply text-sm;
    }
    .opening-hours-small .day-checkbox .day-checkbox-label::before {
        @apply w-6 h-6 mr-2;
    }
    .opening-hours-small .opening-hours-row .opening-hour-right .label-from {
        @apply pr-2;
    }
    .opening-hours-small .opening-hours-row .opening-hour-right .label-to {
        @apply px-2;
    }
    .opening-hours-small .time-input-block {
        @apply flex mr-[6px] w-[88px] h-[40px] rounded-2xl;
    }
    .opening-hours-small .time-input-block .input-control {
        @apply text-sm p-1;
    }
    .opening-hours-small .custom-square-radio-block {
        @apply rounded-lg p-1 w-[72px] h-[40px] rounded-2xl;
    }
    .opening-hours-small .custom-square-radio-block .custome-label {
        @apply text-xs;
    }
    /*-- opening-hours-xsmall --*/
    .opening-hours-xsmall.opening-hours-small .time-input-block {
        @apply w-[64px] rounded-2xl;
    }
    .opening-hours-xsmall.opening-hours-small .custom-square-radio-block {
        @apply w-[58px] rounded-2xl;
    }
    /*-- opening-hours-xsmall over--*/

    .booking-card {
        @apply flex items-center gap-7 py-4 px-[22px] rounded-xl bg-white border border-lineColor;
        box-shadow: 0px 5.33333px 16px 0px rgba(146, 140, 151, 0.1);
    }
    .location-card {
        @apply border-[1px] border-borderGray rounded-2xl hover:shadow flex flex-col gap-4;
        box-shadow: 0px 2px 33px rgba(0, 0, 0, 0.05);
    }

    .location-card-heading {
        @apply text-gray-600 text-xs font-normal mb-1.5;
    }

    .custom-icon {
        @apply h-5 w-5 cursor-pointer;
    }

    .modal_backdrop {
        @apply bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 !z-[9999] !important;
    }
    .modal_content {
        @apply fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0  max-h-full flex items-center justify-center !important;
    }

    .fl-tab {
        @apply p-1 rounded-full bg-inputBg flex items-center;
    }
    .fl-tab .fl-tab-link {
        @apply flex rounded-full p-[10px] -tracking-[0.4px] flex-auto justify-center items-center font-medium text-secondaryTxtColor cursor-pointer;
    }
    .fl-tab .fl-tab-link.active {
        @apply text-mainTextColor bg-white cursor-pointer;
        box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.05);
    }

    .fl-tab-underline {
        @apply flex items-center border-b border-borderSecondary;
    }
    .fl-tab-underline .fl-tab-link {
        @apply min-w-[117px] px-1 pb-2 text-sm leading-[140%] -tracking-[0.32px] flex justify-center items-center font-semibold text-secondaryTxtColor relative transition-all ease-linear duration-75 cursor-pointer;
    }
    .fl-tab-underline .fl-tab-link::after {
        @apply bg-primary h-[3px] left-0 right-0 -bottom-[2px] absolute rounded-[4px] transition-all ease-linear duration-75 opacity-0;
        content: '';
    }
    .fl-tab-underline .fl-tab-link.active {
        @apply text-primary;
    }
    .fl-tab-underline .fl-tab-link.active::after {
        @apply opacity-100;
    }
    .custom-tip-checkbox-block {
        @apply relative;
    }
    .custom-tip-checkbox-block .custom-tip-checkbox {
        @apply absolute left-0 top-0 w-full  h-full opacity-0 cursor-pointer;
    }
    .custom-tip-checkbox-block .custom-tip-label {
        @apply text-start font-normal text-gray-500 text-xs leading-[18px] py-[9px] px-3 pr-4 rounded-lg border border-borderGray shadow-InputAndButton flex justify-start items-center;
    }

    .custom-tip-checkbox-block .custom-tip-label:not([type='text']) {
        @apply cursor-pointer;
    }
    .custom-tip-checkbox-block .custom-tip-checkbox:checked + .custom-tip-label {
        @apply border-primary text-mainTextColor;
    }
    .custom-tip-checkbox-block .custom-tip-label[type='text'] {
        @apply shadow-none outline-none ring-0 focus:shadow-none focus:outline-none focus:ring-0 focus:border-primary;
    }
    .detail-page-title-block {
        @apply p-6 border-b border-lineColor flex items-center justify-between;
    }
    .detail-page-data-block {
        @apply p-6 border-b border-lineColor flex items-start justify-between;
    }
    .detail-page-data-block .title-block {
        @apply w-[280px] min-w-[280px] max-w-[280px] xlm:w-[310px] xlm:min-w-[310px] xlm:max-w-[310px];
    }
    .detail-page-data-block .title-block .title-h3 {
        @apply text-base font-bold mb-2 leading-[140%] -tracking-[0.4px];
    }
    .detail-page-data-block .title-block .title-block-text {
        @apply text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.3px];
    }
    .detail-page-data-block .data-wrap-block {
        @apply flex-1;
    }
    .location-detail-tips-detail-selection {
        @apply p-6 !important;
    }

    .flair-pagination {
        @apply flex items-center;
    }

    .flair-pagination .page-item {
        @apply text-secondaryTxtColor;
    }
    .flair-pagination .page-item.action-arrow {
        @apply text-mainTextColor;
    }
    .flair-pagination .page-item.action-arrow.disabled {
        @apply text-secondaryTxtColor;
    }
    .flair-pagination .page-item.active {
        @apply text-mainTextColor font-bold;
    }
    .staff-table {
        @apply font-medium -tracking-[0.14px] w-full;
    }
    .staff-table tbody tr:first-child td {
        @apply pt-0;
    }
    .staff-table tbody tr:last-child td {
        @apply pb-0;
    }
    .staff-table tbody tr td {
        @apply py-[10px];
    }
    /*-- -*/
    .staff-card {
        @apply border border-lineColor rounded-3xl p-6 pt-5;
    }

    /*-- drawer-menu-block -*/
    .drawer-menu-block {
        @apply border-r border-lineColor min-h-full w-[380px] grow-0 shrink-0 basis-[380px];
    }
    .drawer-content-block {
        @apply flex-1 max-w-[706px];
    }
    .staff-drawer-opening-hours {
        @apply border border-lineColor rounded-3xl bg-white drop-shadow-cardShadow p-8 !important;
    }
    .staff-drawer-opening-hours .opening-hours-row {
        @apply px-0 !important;
    }

    .brd-dashed-img {
        @apply border-dashed border-primary;
    }

    /*-- flush-accordian --*/
    .flush-accordian {
        @apply border-0 border-b !important;
    }
    .flush-accordian-title {
        @apply pl-8 pr-0 py-6 !important;
    }
    .flush-accordian-title,
    .flush-accordian-title:hover,
    .flush-accordian-title:focus,
    .flush-accordian-title:active,
    .flush-accordian-title.bg-gray-100 {
        @apply bg-white ring-0 shadow-none outline-0;
    }
    .flush-accordian-content {
        @apply border-0 pl-8 pr-0 pt-0 !important;
    }

    .theme-box {
        @apply p-3 rounded-[14px] border border-lineColor focus:border-primary active:border-primary hover:border-primary transition-all ease-in duration-75 relative cursor-pointer;
    }
    .theme-box .icon {
        @apply transition-all opacity-0 absolute -top-[6px] -right-[6px] z-10;
    }
    .theme-box.active {
        @apply border-primary;
    }
    .theme-box.active .icon {
        @apply opacity-100;
    }

    .theme-box-title {
        @apply text-sm font-semibold text-center text-gray-700;
    }

    /*-- -*/
    .fl-web-app-tab-content svg {
        width: 100%;
        height: auto;
    }

    /*--- Calendar ---*/
    .rbc-day-slot .rbc-events-container .rbc-event {
        @apply border-0;
    }

    .fl-listing {
        @apply list-none flex flex-col -tracking-[0.14px];
    }
    .fl-listing li {
        @apply flex;
    }
    .fl-listing li:not(:last-child) {
        @apply mb-2;
    }
    .fl-listing li .icon {
        @apply mt-[2px] mr-1 grow-0 shrink-0 basis-[16px];
    }
    .fl-listing li .fl-text {
        @apply flex-auto;
    }

    .fl-listing.list-disc li {
        @apply relative;
    }
    .fl-listing.list-disc li:not(:last-child) {
        @apply mb-1;
    }
    .fl-listing.list-disc li:before {
        @apply bg-mainTextColor w-1 h-1 basis-1 grow-0 shrink-0 mr-[6px] mt-[6px] rounded-full;
        content: '';
    }

    /*-- payment method radio --*/
    .payment-radio-block {
        @apply relative cursor-pointer border-gray-200 border hover:border-[#7191EF] rounded-lg pt-1.5 pr-2.5 pb-1.5 pl-2 min-h-[48px] mb-2 w-full;
    }
    .payment-radio-block.disabled {
        @apply border-gray-200 border hover:border-gray-200 cursor-auto;
    }
    .payment-radio-block .payment-radio {
        @apply hidden;
    }
    .payment-radio-block .payment-radio-content .payment-icon-container {
        @apply border border-gray-200 bg-transparent rounded-[6px] h-9 w-9 flex items-center justify-center;
    }
    .payment-radio-block .payment-radio-content .payment-icon-container .payment-icon {
        @apply text-[#344054] w-5;
    }
    .payment-radio-block .payment-radio-text {
        @apply text-gray-900 font-semibold text-xs leading-[18px];
    }
    .payment-radio-block .payment-radio-content {
        @apply flex w-full transition ease-linear;
    }
    .payment-radio-block .payment-radio:hover + .payment-radio-content,
    .payment-radio-block.active .payment-radio + .payment-radio-content {
        @apply border-[#7191EF];
    }
    .payment-radio-block.active {
        @apply border-[#7191EF] bg-[#F6F8FF];
    }
    .payment-radio-block.active .payment-radio-text {
        @apply text-[#1642C5];
    }
    .payment-radio-block.active .payment-icon-container {
        @apply border-[#7191EF] bg-white;
    }
    .payment-radio-block.active .payment-icon-container .payment-icon {
        @apply text-[#1642C5];
    }

    .fl-web-app-header {
        @apply pb-4 px-7;
        box-shadow: 0px 1px 0px 0px rgba(219, 219, 219, 0.55);
    }
    .fl-web-app-header img {
        @apply max-h-[60px];
    }

    /* start  */
    .fwpo__steper {
        @apply mx-auto flex items-center justify-between;
    }

    .steps__round {
        @apply flex bg-white border-2 border-[#EAECF0] h-8 w-8 shrink-0 items-center justify-center rounded-full text-inputBg p-1;
    }

    .steper_text {
        @apply absolute top-0 mt-10 w-48 -tracking-[0.12px] text-center text-gray-700 text-xs font-medium;
    }
    .login_steper .steper_text {
        @apply relative top-0 mt-0 w-48 -tracking-[0.12px] text-left !text-gray-700 text-[16px] leading-[24px] font-semibold mb-[2px];
    }

    .border_start {
        @apply flex-auto border-t-2;
    }
    .step_completed .steps__round {
        @apply bg-primary text-white p-[3px];
    }
    .step_completed .steper_text {
        @apply text-primary;
    }
    .login_steper .step_completed .steper_text {
        @apply text-gray-700;
    }

    .step_completed .border_start {
        @apply border-primary;
    }

    .step_inprogress .steps__round {
        @apply bg-primary text-white;
    }

    .step_inprogress .steper_text {
        @apply text-primary;
    }
    .payment_steps .step_completed .steper_text {
        @apply text-gray-700;
    }

    @import 'navbar.css';
    @import 'form.css';
    @import 'location.css';
    @import 'apprearance.css';
    @import 'services.css';
    @import 'team.css';
}
