/*-- data view --*/
.fl-data-block {
    @apply flex gap-8;
    /* gap-5 xl:gap-6  */
}
.title-text-header {
    @apply text-sm font-semibold text-[#101828];
}
.title-text {
    @apply text-sm font-semibold text-[#344054];
}
.subtitle-text {
    @apply text-gray-600 font-normal text-sm;
}
.TemplateSettingsNav {
    @apply bg-white fixed top-0 left-0 bottom-0 w-[256px] xxl:w-[296px] pt-[65px] z-0;
    box-shadow: 1px 0px 0px 0px rgba(219, 219, 219, 0.4);
}
.TemplateSettingsNavWrap {
    @apply bg-sidebarBg rounded-xl;
}
.TemplateSettingsNavWrap {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    width: 100%;
    overflow-y: auto;
    padding: 0px 12px;
    padding-top: 16px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.TemplateSettingsNavWrap::-webkit-scrollbar {
    display: none;
}
.TemplateSettingsNavList > li:not(:last-child) {
    @apply mb-2;
}
.TemplateSettingsNavList li {
    @apply flex py-2 px-3 text-xs leading-[150%] font-semibold rounded-md text-secondaryTxtColor transition-all ease-in duration-150 cursor-pointer;
}
.TemplateSettingsNavList li .icon {
    @apply grow-0 shrink-0 basis-5 w-5 flex items-center justify-center;
}
.TemplateSettingsNavList li .text {
    @apply ml-3 flex items-center;
}
.TemplateSettingsNavList li .text > .badge {
    @apply ml-3 flex items-center rounded-full font-normal px-[6px] py-[3px];
}
.TemplateSettingsNavList li .text > .badge > span {
    @apply -tracking-[0.12px] leading-[150%] whitespace-nowrap;
}
.TemplateSettingsNavList li:hover,
.TemplateSettingsNavList li.active {
    @apply bg-sidebarMenuDropDownBg text-mainTextColor;
}

.template-theme-block-thumb {
    @apply border border-borderSecondary rounded-[14px] p-[3px] w-[190px] xl:w-[200px] xxl:w-[230px];
}
.template-theme-block-thumb-inner {
    @apply bg-gray-200 rounded-xl p-3;
}
.template-theme-block-thumb-inner .thumb-img {
    @apply w-full;
}
