:root {
    --primary: #3570f8;
    --mainTextColor: #101828;
    --secondaryTxtColor: #475467;
    --textGray700: #344054;
    --textGray400: #98a2b3;
    --baseBg: #fbfbff;
    --inputBg: #eff1f5;
    --green: #5eac20;
    --borderGray: #dbdbdb;
    --lineColor: #e3e7ef;
    --lineLightColor: #eeeef1;
    --dangerText: #e33b3b;
    --dangerBgLight: #f9e0e0;
    --white: #ffffff;
    --black: #000000;
}

@import '../logofonts/inter/inter.css';
@import '../logofonts/poppins/poppins.css';
@import '../logofonts/roboto/roboto.css';
@import '../logofonts/mulish/mulish.css';
@import '../logofonts/montserrat/montserrat.css';
@import '../logofonts/PlusJakartaSans/PlusJakartaSans.css';
@import '../logofonts/merriweather/merriweather.css';
@import '../logofonts/raleway/raleway.css';
@import '../logofonts/RobotSlab/RobotoSlab.css';

* {
    font-family: 'Inter', sans-serif;
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.inter {
    font-family: 'Inter', sans-serif;
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.roboto {
    font-family: 'Roboto', sans-serif;
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.poppins {
    font-family: 'Poppins', sans-serif;
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.mulish {
    font-family: 'Mulish', sans-serif;
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.montserrat {
    font-family: 'Montserrat', sans-serif;
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.plus_jakarta_sans {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.roboto_slab {
    font-family: 'roboto_slab';
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.merriweather {
    font-family: 'merriweather';
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.raleway {
    font-family: 'raleway';
    font-feature-settings: normal;
    font-variation-settings: normal;
}

::-webkit-scrollbar {
    width: 5px;
    /* height: 6px; */
}
::-webkit-scrollbar-track {
    background: #ebebeb;
}
::-webkit-scrollbar-thumb {
    background: #c4c4c499;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--secondaryTxtColor);
}

#root {
    height: 100%;
}
body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--mainTextColor);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
.imgupload {
    display: none;
}

input::-webkit-color-swatch {
    border: none;
}
input[type='color'] {
    -webkit-appearance: none;
    border: none;
}
input[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
}
input[type='color']::-webkit-color-swatch {
    border: none;
}
input[type='color'] {
    border-radius: 100px;
    background: none;
}

.d-none {
    display: none;
}

.fl-btn {
    height: 40px;
}
.wrapper_content_step {
    min-height: calc(100vh - 65px - 64px);
}
.fwpo_select__indicator-separator {
    display: none;
}
.fl-dropdown-shadow {
    box-shadow: 0px 5.33333px 16px 0px rgba(146, 140, 151, 0.25);
}
.fwpo_select__menu {
    padding: 0px !important;
    margin: 8px 0px !important;
}
.fwpo_select__menu .fwpo_select__menu-list {
    @apply scrollbar-hide p-2;
}
.without_border_select_box .fwpo_select__menu .fwpo_select__menu-list {
    min-width: 180px !important;
}
.fwpo_select__menu-notice {
    @apply text-[11px] !text-[#344054] font-bold;
}
.fwpo_select__menu .fwpo_select__option {
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.3s ease;
}
.fwpo_select__option--is-focused {
    background-color: transparent !important;
}
.fwpo_select__option--is-focused:hover {
    color: var(--mainTextColor);
    background-color: var(--inputBg) !important;
}
.fwpo_select__option:hover,
.fwpo_select__option--is-selected,
.fwpo_select__option--is-focused {
    background-color: #f9fafb !important;
    border-radius: 6px !important;
    @apply !text-primary;
}
.fwpo_select__option:hover .sub-label,
.fwpo_select__option--is-selected .sub-label,
.fwpo_select__option--is-focused .sub-label {
    @apply text-primary;
}

.company-logo .CircularProgressbar .CircularProgressbar-text {
    font-size: 30px !important;
    font-weight: 600;
    letter-spacing: -0.16px;
}

.modal-small > .max-w-2xl {
    max-width: 439px;
}
.modal-small > .max-w-2xl .rounded-lg {
    border-radius: 24px;
}
.modal-small > .max-w-2xl .rounded-lg .p-6 {
    padding: 32px 40px 40px 40px;
}

/*-- Croper css --*/
.ReactCrop {
    display: flex !important;
}

/*-- --*/
.upload-pic-input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    cursor: pointer;
    left: 0;
}
.upload-pic-label svg path {
    stroke: #85888e;
}

.custom-round-chekbox-block,
.custom-round-radio-block {
    position: relative;
}
.custom-round-chekbox-block .custom-round-checkbox,
.custom-round-radio-block .custom-round-radio {
    /* display: none; */
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
}
.custom-round-chekbox-block .custom-round-label,
.custom-round-radio-block .custom-round-label {
    position: relative;
    display: flex;
}
.custom-round-chekbox-block .custom-round-label::before,
.custom-round-radio-block .custom-round-label::before {
    content: '';
    display: flex;
    width: 22px;
    height: 22px;
    border-radius: 30px;
    border: 1px solid var(--lineColor);
    transition: all 0.1s ease;
    cursor: pointer;
}
.custom-round-chekbox-block .custom-round-label::after,
.custom-round-radio-block .custom-round-label::after {
    content: '';
    position: absolute;
    display: inline-block;
    left: 4px;
    top: 4px;
    width: 14px;
    height: 14px;
    border-radius: 30px;
    background-color: var(--primary);
    transition: all 0.09s ease;
    transform: scale(0);
    cursor: pointer;
}
.custom-round-chekbox-block input[type='checkbox']:checked + .custom-round-label::before,
.custom-round-radio-block input[type='radio']:checked + .custom-round-label::before {
    border: 1px solid var(--primary);
}
.custom-round-chekbox-block input[type='checkbox']:checked + .custom-round-label::after,
.custom-round-radio-block input[type='radio']:checked + .custom-round-label::after {
    transform: scale(1);
}
[type='checkbox'],
[type='radio'] {
    color: var(--primary);
}
.header_menu_radio [type='radio']:focus {
    --tw-ring-offset-width: 0px;
    outline: none;
    outline-offset: 0px;
    --tw-ring-color: var(--primary);
}
.dashboard_content_wrapper {
    width: calc(100% - 220px);
    margin-top: 10px;
    margin-left: 220px;
    height: calc(100vh - 10px);
    min-height: calc(100vh - 10px);
    overflow: hidden;
}

@media (min-width: 220px) and (max-width: 1023px) {
    .dashboard_content_wrapper {
        width: calc(100% - 220px);
        margin-left: 220px;
        min-height: calc(100vh - 10px);
    }
}

@media (min-width: 1024px) and (max-width: 1759px) {
    .dashboard_content_wrapper {
        width: calc(100% - 240px);
        margin-left: 240px;
        min-height: calc(100vh - 10px);
    }
}
@media (min-width: 1760px) {
    .dashboard_content_wrapper {
        width: calc(100% - 260px);
        margin-left: 260px;
        min-height: calc(100vh - 10px);
    }
}

/*-- media min-width 1680 --*/
/* @media (min-width: 1680px) {
    .dashboard_content_wrapper {
        width: calc(100% - 296px);
    }
} */

.custom-range-slider input[type='range'] {
    --c: var(--primary); /* active color */
    --g: 3px; /* the gap */
    --l: 2px; /* line thickness*/
    --s: 24px; /* thumb size*/
    width: 100%;
    height: var(--s); /* needed for Firefox*/
    --_c: color-mix(in srgb, var(--c), #000 var(--p, 0%));
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    cursor: pointer;
    overflow: hidden;
}
.custom-range-slider input[type='range']:focus-visible,
.custom-range-slider input[type='range']:hover {
    --p: 25%;
}
.custom-range-slider input[type='range']:active,
.custom-range-slider input[type='range']:focus-visible {
    --_b: var(--s);
}

.custom-range-slider input[type='range']::-webkit-slider-thumb {
    /* height: var(--s); */
    width: 24px;
    height: 24px;
    border: 6px solid var(--primary);
    background-color: var(--white);
    box-sizing: border-box;
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: 0 0 0 var(--_b, var(--l)) inset var(--_c);
    border-image: linear-gradient(90deg, var(--_c) 50%, var(--inputBg) 0) 1/0 100vw/0 calc(100vw + var(--g));
    clip-path: polygon(
        0 calc(50% + var(--l) / 2),
        -100vw calc(50% + var(--l) / 2),
        -100vw calc(50% - var(--l) / 2),
        0 calc(50% - var(--l) / 2),
        0 0,
        100% 0,
        100% calc(50% - var(--l) / 2),
        100vw calc(50% - var(--l) / 2),
        100vw calc(50% + var(--l) / 2),
        100% calc(50% + var(--l) / 2),
        100% 100%,
        0 100%
    );
    -webkit-appearance: none;
    appearance: none;
    transition: 0.3s;
}
.custom-range-slider input[type='range']::-moz-range-thumb {
    /* height: var(--s);
  width: var(--s);
  background: none;*/
    width: 24px;
    height: 24px;
    background-color: var(--white);
    border: 6px solid var(--primary);
    box-sizing: border-box;
    border-radius: 50%;
    box-shadow: 0 0 0 var(--_b, var(--l)) inset var(--_c);
    border-image: linear-gradient(90deg, var(--_c) 50%, var(--inputBg) 0) 1/0 100vw/0 calc(100vw + var(--g));
    clip-path: polygon(
        0 calc(50% + var(--l) / 2),
        -100vw calc(50% + var(--l) / 2),
        -100vw calc(50% - var(--l) / 2),
        0 calc(50% - var(--l) / 2),
        0 0,
        100% 0,
        100% calc(50% - var(--l) / 2),
        100vw calc(50% - var(--l) / 2),
        100vw calc(50% + var(--l) / 2),
        100% calc(50% + var(--l) / 2),
        100% 100%,
        0 100%
    );
    -moz-appearance: none;
    appearance: none;
    transition: 0.3s;
}

.imageCropper .cropper-view-box,
.imageCropper .cropper-face {
    border-radius: 50%;
}

/* The css styles for `outline` do not follow `border-radius` on iOS/Safari (#979). */
.imageCropper .cropper-view-box {
    outline: 0;
    box-shadow: 0 0 0 1px #39f;
}

/** **/
.fwpo_select__control .fwpo_select__input-container .fwpo_select__input {
    /* @apply right-0 shadow-none; */
    outline: none;
    box-shadow: none;
}

/** Outline Select Box **/
/* .fwpo_select__control {
    min-height: unset;
    padding: 6px 8px;
    padding-left: 16px !important;
    height: 48px;
    cursor: pointer !important;
    border-radius: 8px !important;
    background-color: transparent !important;
    border-color: #d0d5dd !important;
} */
.h-10 .fwpo_select__control {
    padding: 4px 8px;
    height: 40px;
}
/* .outline-select-box {
    border-radius: 9999px;
}

.outline-select-box .fwpo_select__control {
    background: transparent;
    border: 1px solid var(--lineColor);
}
.outline-select-box .fwpo_select__control:hover {
    border: 1px solid var(--lineColor);
}
.outline-select-box .fwpo_select__control .fwpo_select__value-container .fwpo_select__single-value {
    color: var(--Gray100);
    font-weight: 500;
    letter-spacing: -0.14px;
}
.outline-select-box .fwpo_select__control .fwpo_select__indicators .fwpo_select__indicator {
    color: var(--secondaryTxtColor);
    padding: 0;
} */

.page-dropdown .fwpo_select__control .fwpo_select__value-container {
    padding: 0px;
}
.fwpo_select__indicators .fwpo_select__indicator {
    padding: 0;
}
.viewLocation .fwpo_select__indicators .fwpo_select__indicator svg {
    fill: var(--textGray700);
}
.fwpo_select__menu .fwpo_select__menu-list {
    max-height: 185px;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.fwpo_select__menu .fwpo_select__menu-list::-webkit-scrollbar {
    display: none;
}
.filterIconCalender .fwpo_select__placeholder {
    margin: 0px;
}
.fwpo_select__value-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    text-transform: capitalize;
}
.fwpo_select__multi-value {
    background-color: var(--primary) !important;
    border-radius: 30px !important;
    color: var(--white) !important;
}
.fwpo_select__multi-value__label {
    font-size: 14px !important;
    padding-left: 10px !important;
    color: var(--white) !important;
}

.fwpo_select__multi-value__remove {
    font-size: 20px !important;
    padding: 0 10px 0 3px !important;
    border-radius: 0 30px 30px 0 !important;
}
.fwpo_select__multi-value__remove:hover {
    color: var(--white) !important;
    background-color: var(--primary) !important;
}
.fwpo_select__multi-value__remove svg {
    width: 17px;
}

.fc-v-event {
    background-color: transparent;
    border: none;
}
.fc .fc-scrollgrid-liquid {
    border: 0;
}
.fc-col-header thead th {
    padding: 4px 20px 16px;
    text-align: start;
}
.fc-col-header thead th .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
    display: block;
    padding: 0;
}
.fc-col-header thead th.fc-day-today a div {
    color: var(--primary);
}
.fc-col-header thead th.fc-day-today,
.fc .fc-timegrid-col.fc-day-today {
    background-color: #f5f5f5;
}

.fc-theme-standard td {
    border-color: #f0f0f0;
}
th.fc-timegrid-axis,
.fc .fc-timegrid-slot-label {
    border: 0;
}
.fc .fc-timegrid-slot-label .fc-timegrid-slot-label-frame .fc-timegrid-slot-label-cushion {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--secondaryTxtColor);
    padding: 0 8px 0 0;
}

/* .brd-dashed-img{
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%231642C5FF' stroke-width='1' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 24px;
  background-clip: border-box;
  background-size: cover;
}*/

.date-field-block .react-datepicker-wrapper {
    width: 100%;
}
.date-field-block .icon {
    opacity: 0.7;
}

.react-datepicker {
    border: 0px solid #eaecf0;
    z-index: 2;
    border-radius: 8px;
    background: var(--white);
    color: var(--mainTextColor) !important;
    box-shadow: 0px 5.33333px 16px 0px rgba(146, 140, 151, 0.25);
}
.react-datepicker-popper {
    z-index: 1;
    transform: translate(0px, 33px) !important;
    width: 100%;
    padding-top: 8px;
}

.recurring-date-down-datepicker .react-datepicker-popper {
    z-index: 1;
    transform: translate(0px, -26px) !important;
    width: 100%;
    padding-top: 8px;
}

.date-range-select .react-datepicker-popper {
    z-index: 1;
    transform: translate(0px, 22px) !important;
    width: 100%;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    font-size: 12px;
    font-weight: 400;
    color: #344054;
}
.react-datepicker--time-only {
    /* min-width: 130px; */
    width: 100% !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
    background: var(--white) !important;
    /* overflow: hidden; */
}
.react-datepicker__time-container {
    width: 100% !important;
}
.react-datepicker__header--time--only {
    display: none !important;
}

.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time {
    background: var(--white) !important;
    padding: 6px;
    border-radius: 10px !important;
    box-shadow: 0px 5.33333px 16px 0px rgba(146, 140, 151, 0.25);
    border: none !important;
}
.react-datepicker__time-container .react-datepicker__time {
    position: absolute !important;
    width: 100% !important;
}

.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    margin: 0;
    width: 100%;
    padding: 0 0;
}
.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list {
    padding: 0;
    height: 100%;
    max-height: 185px;
    border-radius: 12px !important;
    scrollbar-width: none !important;
}
.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item {
    text-align: left;
    margin: 2px 0;
    padding-right: 22px;
}
.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item:hover {
    color: var(--primary);
    font-weight: normal;
    background: #f9fafb;
    border-radius: 6px;
    font-weight: 500;
}

.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected:hover,
.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected {
    color: var(--primary);
    background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important ;
    font-weight: 500;
    background: url(../img/icons/checkbox-checked.svg) no-repeat right center;
    background-size: 24px 24px;
    border-radius: 6px;
    padding-left: 8px;
    padding-right: 8px;
}

.react-datepicker .react-datepicker__triangle::after {
    content: none !important;
}
.react-datepicker .react-datepicker__triangle::before {
    content: none !important;
}
.react-datepicker .react-datepicker__input-container {
    width: 100%;
}
.react-datepicker .react-datepicker__header {
    background-color: transparent;
}
.react-datepicker .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-bottom: none;
}
.react-datepicker .react-datepicker__header .react-datepicker__current-month {
    margin: 16px 0 12px;
    color: #344054;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
}
.react-datepicker .react-datepicker__header {
    padding: 0px;
}
.react-datepicker .react-datepicker__navigation {
    top: 14px;
    border: 0px solid #f1f1f1;
    height: auto;
    width: 38px;
    margin: 0 1rem;
    border-radius: 8px;
    text-indent: unset;
}
.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon {
    position: relative;
    text-indent: -9999px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #101828;
    justify-content: center;
}
.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon::before {
    left: unset;
    top: unset;
    right: unset;
    bottom: unset;
    border-width: 2px 2px 0px 0;
    border-color: var(--secondaryTxtColor);
    border-radius: 1px;
    margin-top: -2px;
}
.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon--next::before {
    margin-left: -3px;
}
.react-datepicker .react-datepicker__day-names .react-datepicker__day-name {
    color: #344054;
    font-size: 12px;
    width: 35px;
    height: 35px;
    margin: 0;
    padding: 0.5rem;
}
.react-datepicker .react-datepicker__day {
    font-size: 12px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0;
    padding: 0.25rem;
}
.react-datepicker .react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
}

.react-datepicker .react-datepicker__day--outside-month:empty {
    background-color: transparent;
}

.react-datepicker .react-datepicker__day--outside-month:empty:after {
    background-color: transparent;
}
.react-datepicker__day--today:empty:before {
    display: none;
}

.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end {
    /* background-color: #3570f8; */
    color: var(--white);
    position: relative;
    outline: none;
}
.react-datepicker .react-datepicker__day--selected:before,
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end:before {
    content: '';
    height: 5px;
    width: 5px;
    background-color: var(--white) !important;
    border-radius: 50%;
    position: absolute;
    top: 77%;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
}
.react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--selected {
    border-radius: 50% !important;
}
.react-datepicker .react-datepicker__day--selected::after {
    content: '';
    position: absolute;
    background-color: #3570f8 !important;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%;
}

.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--selected {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    position: relative;
    z-index: 2;
    color: #fff;
}
.react-datepicker .react-datepicker__day--in-selecting-range.react-datepicker__day--selected {
    background-color: #3570f8 !important;
}
.react-datepicker .react-datepicker__day--selecting-range-start {
    background-color: #3570f8 !important;
}
.react-datepicker .react-datepicker__day--in-range {
    background-color: #f2f4f7;
    border-radius: 0;
    color: #344054;
}
.react-datepicker .react-datepicker__day--selected {
    background-color: #3570f8;
    color: var(--white);
    position: relative;
}
.react-datepicker__week {
    margin-bottom: 4px;
}
.react-datepicker .react-datepicker__day--selected:before {
    content: '';
    height: 5px;
    width: 5px;
    background-color: var(--white);
    border-radius: 50%;
    position: absolute;
    top: 77%;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
}
.react-datepicker .react-datepicker__month {
    margin: 1rem;
}

.react-datepicker .react-datepicker__day--outside-month {
    color: #bec9dd;
}

/* .react-datepicker .react-datepicker__day--in-range:hover {
    background-color: #f0f0f0 !important;
} */
/* .react-datepicker .react-datepicker__week .react-datepicker__day--in-range:first-child {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
} */
.react-datepicker .react-datepicker__day--in-range {
    background-color: #f2f4f7;
    border-radius: 0;
    color: #344054;
}
.react-datepicker .react-datepicker__day--in-selecting-range {
    background-color: #f2f4f7;
}

.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--selected {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    position: relative;
    z-index: 2;
    color: #fff;
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    position: relative;
    z-index: 2;
    color: #fff;
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--selected:after,
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end:after {
    content: '';
    position: absolute;
    background-color: #3570f8;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%;
}

.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--selected::before,
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end::before {
    content: '';
    height: 5px;
    width: 5px;
    background-color: var(--white);
    border-radius: 50%;
    position: absolute;
    top: 77%;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--selected {
    border-radius: 50%;
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end {
    border-radius: 0;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}
.app-logo-img-block > div {
    height: 100% !important;
}

/*-- RBC Calender --*/
/* .rbc-timeslot-group{
  border-color:#F0F0F0;
}
.rbc-time-gutter .rbc-timeslot-group .rbc-time-slot .rbc-time-slot{
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--secondaryTxtColor);
}
.rbc-time-gutter .rbc-timeslot-group .rbc-time-slot{
  border:0;
} */

.pac-container {
    /* display: block !important; */
    border-radius: 12px !important;
    border: 0;
    box-shadow: 0px 5.33333px 16px rgba(146, 140, 151, 0.25) !important;
    padding: 8px;
    /* font-family: 'SF Pro Text'; */
}
.pac-container.pac-logo:after {
    /* padding: 10px;
    background-position-x: calc(100% - 15px);
    background-position-y: center;
    height: 38px; */
    display: none;
}
.pac-container .pac-item {
    padding: 3px 12px;
    border-radius: 8px;
    border: 0;
    height: unset;
    cursor: pointer;
    transition: all 0.3s ease;
}
.pac-container .pac-item .pac-item-query {
    color: #1f293c;
    font-weight: 600;
    line-height: normal;
    transition: all 0.3s ease;
}
.pac-container .pac-item:hover {
    background-color: rgba(22, 66, 197, 0.1);
}
.pac-container .pac-item:hover .pac-item-query,
.pac-container .pac-item:hover {
    color: var(--primary);
}

/*-- fl-table --*/
.fl-table {
    @apply text-sm;
}
.fl-table thead tr th {
    @apply p-2 text-xs text-left font-normal text-secondaryTxtColor -tracking-[0.12px] border-b border-lineColor;
}
.fl-table tbody tr td {
    @apply py-3 px-2;
}
.fl-table thead tr th:first-child,
.fl-table tbody tr td:first-child {
    @apply pl-4;
}
.fl-table thead tr th:last-child,
.fl-table tbody tr td:last-child {
    @apply pr-4;
}

.btn-spin-icon {
    animation: animBtn 1.2s infinite linear;
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

@keyframes animBtn {
    100% {
        transform: rotate(360deg);
    }
}

.cropper-modal {
    background-color: black !important;
    opacity: 0.5 !important;
}
/* .cropper-crop-box {
    width: 310px !important;
    height: 310px !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
} */
.cropper-crop-box .cropper-view-box {
    box-shadow: none;
    outline: 2px solid #3885ff;
    outline-color: #3885ff;
}

.working_location .fwpo_select__control {
    height: 100%;
}

.working_location .fwpo_select__value-container {
    /* max-height: 37px; */
    /* overflow: auto; */
    margin-right: 5px;
}

.working_location .fwpo_select__value-container::-webkit-scrollbar {
    width: 2px;
}

.hyperlinks .form_input_control {
    padding-right: 45px;
}

.notes_loader .btn-spin-icon {
    position: absolute;
}

.calendar-block {
    height: calc(100vh - 93px);
}
.rbc-time-view .rbc-time-header {
    margin-right: 0 !important;
    border-right: 0 !important;
}
.p-dialog-header-icons {
    @apply relative;
}
.p-dialog-header-icons button {
    @apply flex items-center justify-end h-[46px] !p-0  text-secondaryTxtColor hover:text-black !outline-none !ring-0;
}
.rbc-time-content {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    border-bottom: 1px solid #e0e0e0;
}
.calendar-block .rbc-time-content::-webkit-scrollbar {
    display: none;
}
.sketch-picker {
    position: absolute;
    top: 45px;
    right: 0;
    z-index: 10;
}
.sketch-picker .flexbox-fix input,
.sketch-picker .flexbox-fix label {
    /* display: none !important; */
}
.alltime_btn div {
    justify-content: space-between;
    width: 100%;
}
.drawer_body .p-datatable .p-datatable-thead > tr > th:first-child,
.drawer_body .p-datatable .p-datatable-tbody > tr > td:first-child {
    padding-left: 46px;
}
.drawer_body .p-datatable .p-paginator {
    padding-left: 46px;
}
.drawer_body .p-datatable .p-paginator .p-paginator-pages {
    padding: 0 10px;
}
.drawer_body .p-datatable .p-paginator .p-link {
    font-size: 14px;
}
.drawer_body .p-datatable-table {
    width: 100%;
}
.notification_modal .p-datatable .p-datatable-tbody > tr > td {
    padding: 0px 0px 0px 0px !important;
    /* padding-left: 0 !important; */
}
/* .notification_modal .p-datatable .p-datatable-tbody > tr > td:last-child {
    padding: 0px 0px 16px 0px !important;
} */
.notification_modal .p-datatable .p-paginator {
    padding-left: 16px;
}

.h-auto {
    height: auto;
}

.gray-banner-bg::after {
    content: '';
    position: absolute;
    right: 0;
    background-image: url('../img/background_shap.svg');
    background-repeat: no-repeat;
    background-size: auto;
    height: 100%;
    width: 100%;
    top: 0%;
    left: 70%;
}
.bgshapmodel::after {
    content: '';
    position: absolute;
    right: 0;
    background-image: url('../img/bgshapmodel.svg');
    background-repeat: no-repeat;
    background-size: auto;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    top: 0%;
    left: 0%;
    z-index: 1;
    pointer-events: none;
}

.orm-radio:checked[type='radio'] {
    background-image: url('../svgs/blue_dot.svg');
    color: white;
    border: 1px solid var(--primary);
}
.orm-radio:focus {
    box-shadow: none;
}
.account_active svg path,
.account_active,
.account_active h4 {
    color: var(--primary);
    stroke: var(--primary);
}
.account_active > div {
    border-color: var(--primary);
}

/* .brand_social svg path {
    fill: #667085;
} */
.tagify {
    border-color: #d0d5dd !important;
    align-items: center !important;
    height: auto !important;
    min-height: 36px !important;
}
.tagify:hover {
    border-color: #7b7e85 !important;
}
.tagify__input {
    line-height: 12px !important;
    margin: 7px !important;
}
.tagify__input::before {
    color: #667085 !important;
    font-size: 12px;
}
.tagify__tag-text {
    font-size: 12px;
}
.tagify__tag {
    margin-left: 14px !important;
}
.tagify__tag > div::before {
    box-shadow: none !important;
    border: 1px solid #d0d5dd;
    border-radius: 6px !important;
}
.custom-range-slider input[type='range'] {
    --c: #0e34a5;
    --g: 1px;
    --l: 3px;
    --s: 24px;
    --_c: #0e34a5;
}
.main_slider .swiper-pagination {
    display: flex;
    justify-content: flex-end;
    bottom: 36px !important;
    padding-right: 60px;
}
.main_slider .swiper-pagination-bullet {
    color: transparent;
    width: 10px;
    height: 10px;
    background-color: white;
    opacity: 0.3;
    transition: all 0.5s ease-in-out;
    border-radius: 6px;
}
.main_slider .swiper-pagination-bullet-active {
    opacity: 1;
    width: 54px;
}
@media (min-width: 768px) {
    .md\:h-auto {
        height: auto !important;
    }
}
.custom-loading {
    position: relative;
    /* margin-bottom: 1px;
     border: 0; */
    visibility: hidden;

    &:after {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        background-color: #eaecf0;
        border-radius: inherit;
        visibility: visible;
    }

    &.loading-dark {
        &:after {
            background-color: #d1d5db;
        }
    }

    &.image-loading {
        &:after {
            background-image: url('../../assets/img/icons/image-loading.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 25% 25%;
        }
    }

    &.user-loading {
        &:after {
            background-image: url('../../assets/img/icons/user-loading.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
        }
    }

    &.graph-loading {
        visibility: visible;

        text {
            visibility: hidden;
        }

        svg {
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

            path {
                fill: #eaecf0;
                visibility: visible;
                filter: none;
            }
        }

        &:after {
            background-color: transparent;
        }
    }
}
.capitalize-first {
    text-transform: lowercase !important;
}
.capitalize-first::first-letter {
    text-transform: uppercase !important;
}
.asterisk {
    @apply text-[#dd0202b2] text-xs font-medium ms-[2px];
}
.p-progress-spinner-circle {
    stroke: #000000 !important;
}
.radio-loader .p-progress-spinner-circle {
    stroke: var(--primary) !important;
}
.p-datatable {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.p-datatable-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.datatable-full-height .p-datatable-table {
    flex: 1;
}

.datatable-full-height .p-datatable-thead {
    @apply !bg-gray-50;
}

/* .datatable-full-height tbody .p-datatable-row-expansion td .p-datatable-tbody tr {
    @apply !bg-gray-50
} */

.datatable-full-height-client .p-datatable-table {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}
.datatable-full-height-client tbody tr {
    width: 100%;
    display: flex;
    flex: 1;
    height: auto;
}
.datatable-full-height-client .p-datatable-tbody {
    display: contents;
}
.datatable-full-height-client.datatable-custom-service.service_table .p-datatable .p-datatable-tbody > tr > td:last-child {
    width: 100% !important;
    display: flex;
    height: auto;
    flex: 1;
}

/* .p-column-header-content
{
    width: max-content;
} */
.p-column-header-content .p-column-title {
    display: flex;
    flex-direction: row;
    gap: 4px;
    text-transform: capitalize;
    align-items: center;
    width: max-content;
    min-width: max-content;
}
.table-pagination {
    @apply flex border-t justify-between !py-3 overflow-hidden rounded-b-xl;
}
.analytics-pagination .table-pagination {
    @apply flex border-t justify-between !pt-4 !pb-3 overflow-hidden rounded-b-xl;
}
.custom-scroll::-webkit-scrollbar {
    width: 6px;
}
.custom-scroll::-webkit-scrollbar-track {
    background: #ebebeb;
}
.custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #c4c4c499;
}
.card-shadow {
    box-shadow: 0px 4px 6px -2px #10182808;
    box-shadow: 0px 12px 16px -4px #10182814;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
    display: none;
}
.p-datatable .p-datatable-thead {
    background-color: #f9fafb !important;
    height: 40.5px !important;
    min-height: 40.5px !important;
    max-height: 40.5px !important;
    --moz-min-height: 40.5px !important;
    --moz-max-height: 40.5px !important;
    --webkit-height: 40.5px !important;
    --moz-height: 40.5px !important;
    --ms-height: 40.5px !important;
    --o-height: 40.5px !important;
}
.searchBoxShadow {
    box-shadow: 0px 0px 0px 4px #9e77ed3d;
}

.filterWraper .select_border .fwpo_select__control {
    height: fit-content !important;
    padding: 0px !important;
}
.filterWraper .select_border {
    border: none !important;
    box-shadow: none !important;
}
.filterWraper .fwpo_select__indicators {
    display: none;
}
.filterWraper .fwpo_select__value-container {
    padding: 0px !important;
}
.filterWraper .fwpo_select__single-value {
    margin: 0px !important;
    padding: 0px !important;
}
.filterWraper .fwpo_select__menu {
    right: 0px !important;
    min-width: 165px !important;
}
.min-width-165 .fwpo_select__menu {
    min-width: 165px !important;
}
.recurring-label-height .fwpo_select__menu .fwpo_select__menu-list {
    max-height: 150px !important;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.recurring-date .react-datepicker-popper {
    z-index: 1;
}

.modal-input-field [type='number'] {
    text-overflow: ellipsis !important;
    border-width: 1px !important;
    font-size: 36px !important;
    line-height: 1rem !important;
    line-height: 18px !important;
}

div[data-testid='flowbite-tooltip-arrow'] {
    background-color: #101828 !important;
    position: absolute !important;
    z-index: 10 !important;
    transform: rotate(45deg) !important;
    padding: 8px !important;
    left: 50% !important; /* Positions the arrow horizontally at the center */
    transform: translateX(-50%) rotate(45deg) !important; /* Centers the arrow horizontally */
}
.p-tooltip-arrow {
    position: absolute !important;
    width: 0 !important;
    height: 0 !important;
    border-color: transparent !important;
    border-style: solid !important;
}
.p-tooltip-arrow {
    position: absolute !important;
    width: 0 !important;
    height: 0 !important;
    border-color: transparent !important;
    border-style: solid !important;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #000000 !important;
}
.p-tooltip-top .p-tooltip-arrow {
    bottom: 0 !important;
    left: 50% !important;
    margin-left: -0.25rem !important;
    border-width: 0.25em 0.25em 0 !important;
}
.p-tooltip .p-tooltip-text {
    background: #000000 !important;
    color: #ffffff !important;
    padding: 6px 10px !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.wal-hq-select .hide-select-value-container .select__control {
    display: none;
}
.wal-hq-select .hide-select-value-container .date-picker-menu {
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.wal-hq-select .hide-select-value-container .select__menu {
    position: relative;
    box-shadow: none;
    background-color: transparent;
    margin: 0;
    z-index: auto;
    border-radius: 0;
    border: none;
}
.wal-hq-select .hide-select-value-container .select__menu-list {
    max-height: 416px;
    min-width: 189px;
    padding: 12px 8px 8px 8px;
    margin: 0;
}
.wal-hq-select .hide-select-value-container .select__menu-list::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}
.wal-hq-select .hide-select-value-container .select__menu-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #eaecf0;
}
@media (min-width: 1024px) {
    .wal-hq-select .hide-select-value-container .select__menu-list {
        min-width: 136px;
    }
}
@media (min-width: 1360px) {
    .wal-hq-select .hide-select-value-container .select__menu-list {
        min-width: 156px;
    }
}
@media (min-width: 1760px) {
    .wal-hq-select .hide-select-value-container .select__menu-list {
        min-width: 189px;
    }
}

/* start date range picker */
.wal-hq-select .select__menu-list {
    overflow-x: auto;
    overflow-y: auto;
    padding: 0;
    max-height: 145px;
    margin: 4px 6px;
}
.wal-hq-select .select__menu-list::-webkit-scrollbar {
    height: 0;
    width: 4px;
}
.wal-hq-select .select__menu-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #eaecf0;
}
.wal-hq-select .select__control {
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    overflow-x: auto;
    min-height: 34px;
    height: fit-content;
    border-color: #d0d5dd;
}
@media (min-width: 1024px) {
    .wal-hq-select .select__control {
        min-height: 36px;
    }
}
.wal-hq-select .select__control:focus {
    border-color: '#FFFBFA';
    box-shadow: none;
}
.wal-hq-select .select__control:focus-within {
    border-color: '#FFFBFA';
    box-shadow: none;
}
.wal-hq-select .select__control:active {
    border-color: '#FFFBFA';
    box-shadow: none;
}
.wal-hq-select .select__control:hover {
    border-color: '#FFFBFA';
    box-shadow: none;
}
.wal-hq-select .select__control .select__value-container {
    color: #667085;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    flex-wrap: nowrap !important;
    overflow-x: auto;
    padding: 4px 10px;
}
@media (max-width: 1024px) {
    .wal-hq-select .select__control .select__value-container {
        padding: 3px 10px;
    }
}
.wal-hq-select .select__control .select__value-container::-webkit-scrollbar {
    height: 2px;
}
.wal-hq-select .select__control .select__value-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.wal-hq-select .select__control .select__value-container::-webkit-scrollbar-thumb {
    background: '#53B1FD';
}
.wal-hq-select .select__control .select__value-container::-webkit-scrollbar-thumb:hover {
    background: '#53B1FD';
}
.wal-hq-select .select__control .select__value-container .select__input-container .select__input {
    outline: none;
    box-shadow: unset;
}
.wal-hq-select .select__control .select__value-container .css-olqui2-singleValue {
    color: #667085;
}
.wal-hq-select .select__control .select__indicators svg {
    color: #667085;
    width: 16px;
}
.wal-hq-select .select__control .select__indicators .select__indicator-separator {
    display: none;
}
.wal-hq-select .select__control .select__placeholder {
    color: #667085;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.wal-hq-select .select__control .select__multi-value {
    background-color: '#ffffff' !important;
    display: flex;
    border-radius: 6px !important;
    min-width: 100px !important;
    border: 1px solid #d0d5dd !important;
    margin: 0 4px;
    align-items: center;
}
.wal-hq-select .select__control .select__multi-value__label {
    font-size: 12px !important;
    padding: 2px;
}
.wal-hq-select .select__control .select__multi-value__remove:hover {
    color: '#F04438';
    background-color: #fff;
}
.wal-hq-select .select__menu {
    border-radius: 8px;
    margin-top: 10px;
    overflow: hidden;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    cursor: pointer;
    z-index: 30;
    border: 1px solid #eaecf0;
}
.wal-hq-select .select__option {
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 12px;
    color: #101828;
    line-height: 18px;
    background-color: #fff;
    padding: 8px;
    border-radius: 0;
}
@media (min-width: 1360px) {
    .wal-hq-select .select__option {
        padding: 8px;
    }
}
.wal-hq-select .select__option .sub-label {
    color: #475467;
}

.wal-hq-select .select__option.select__option--is-focused {
    background-color: transparent;
}
.wal-hq-select .select__option:hover,
.wal-hq-select .select__option.select__option--is-selected {
    background-color: #f9fafb;
    border-radius: 6px;
    color: #3570f8;
}
.wal-hq-select .select__option:hover .sub-label,
.wal-hq-select .select__option.select__option--is-selected .sub-label,
.wal-hq-select .select__option.select__option--is-focused .sub-label {
    color: #3570f8;
}
.wal-hq-select .select__control--is-disabled {
    background-color: #f9fafb;
}
.wal-hq-select .select__control--is-disabled .select__indicators {
    display: none;
}
.wal-hq-select .select__indicator {
    color: #000;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0;
}

/* new date picker */

.datepicker-modal .custom-time-range > div:nth-child(1) {
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 5px;
    width: calc(100% - 5px);
}
.datepicker-modal .custom-time-range > div:nth-child(1)::-webkit-scrollbar {
    width: 4px;
}
.datepicker-modal .custom-time-range > div:nth-child(1)::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #eaecf0;
}
.datepicker-modal .custom-time-range > div:nth-child(1) .react-datepicker {
    min-height: 299px;
}
@media (min-width: 1360px) {
    .datepicker-modal .custom-time-range > div:nth-child(1) .react-datepicker {
        min-height: 334px;
    }
}
@media (max-width: 1360px) {
    .custom-time-range {
        min-width: 482px !important;
    }
}
.custom-time-range {
    min-width: 590px;
}

.custom-time-range .react-datepicker {
    border-radius: 0;
    border: 0px solid transparent !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: none;
    background-color: transparent;
}

.custom-time-range .react-datepicker {
    border-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: none;
    background-color: transparent;
}
.custom-time-range .react-datepicker .react-datepicker__day--today {
    position: relative;
}
.custom-time-range .react-datepicker .react-datepicker__day--today:before {
    content: '';
    height: 5px;
    width: 5px;
    background-color: #3570f8;
    border-radius: 50%;
    position: absolute;
    top: 77%;
    left: 50%;
    transform: translateX(-50%);
}
.custom-time-range .react-datepicker .react-datepicker__day--today.react-datepicker__day--selected:before {
    background-color: #fff !important;
}
.custom-time-range .react-datepicker .react-datepicker__day--today.react-datepicker__day--selected:before {
    background-color: #fff;
}
.custom-time-range .react-datepicker__day--in-selecting-range {
    background-color: #f2f4f7;
}
.custom-time-range .react-datepicker__day--in-selecting-range.react-datepicker__day--selected {
    background-color: #3570f8;
}
.custom-time-range .react-datepicker__header {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}
.custom-time-range .react-datepicker__header .react-datepicker__current-month {
    margin: 16px 0 12px;
    color: #344054;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
}
.custom-time-range .react-datepicker__header .react-datepicker__navigation-icon::before {
    border-color: #344054;
    top: 13px;
}
@media (min-width: 1024px) {
    .custom-time-range .react-datepicker__month-container:last-child {
        border-left: 1px solid #eaecf0;
    }
}
.custom-time-range .react-datepicker__navigation {
    border: 0;
    height: 30px;
    width: 30px;
    margin: 0 15px;
    top: 10px;
}
@media (min-width: 1024px) {
    .custom-time-range .react-datepicker__navigation {
        width: 30px;
        margin: 0 15px;
    }
}
@media (min-width: 1360px) {
    .custom-time-range .react-datepicker__navigation {
        width: 35px;
        margin: 0 18px;
    }
}
.custom-time-range .react-datepicker__navigation .react-datepicker__navigation-icon::before {
    border-color: #344054;
    top: 13px;
}
.custom-time-range .react-datepicker__month {
    margin: 0 15px 16px;
    padding-bottom: 20px;
}
@media (min-width: 1360px) {
    .custom-time-range .react-datepicker__month {
        margin: 0 24px 9px;
    }
}
.custom-time-range .react-datepicker__header {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.custom-time-range .react-datepicker__header .react-datepicker__day-names {
    flex: 0 0 100%;
}
.custom-time-range .react-datepicker__header .react-datepicker__current-month {
    margin: 16px 0 12px;
    color: #344054;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
}
.custom-time-range .react-datepicker__header .react-datepicker__header__dropdown--scroll .react-datepicker__year-dropdown-container {
    font-family: Inter;
    margin: 10px 10px 12px;
}
.custom-time-range .react-datepicker__header .react-datepicker__header__dropdown--scroll .react-datepicker__year-dropdown-container .react-datepicker__year-option self:not(:first-child, :last-child) {
    color: #475467;
    padding: 5px 0;
    margin-bottom: 4px;
    font-size: 12px;
    border-radius: 6px;
    transition: 0.5s ease all;
}
.custom-time-range
    .react-datepicker__header
    .react-datepicker__header__dropdown--scroll
    .react-datepicker__year-dropdown-container
    .react-datepicker__year-option
    self:not(:first-child, :last-child):hover,
.custom-time-range
    .react-datepicker__header
    .react-datepicker__header__dropdown--scroll
    .react-datepicker__year-dropdown-container
    .react-datepicker__year-option
    self:not(:first-child, :last-child).react-datepicker__year-option--selected_year {
    background-color: #eef4ff;
    color: #3570f8;
}
.custom-time-range
    .react-datepicker__header
    .react-datepicker__header__dropdown--scroll
    .react-datepicker__year-dropdown-container
    .react-datepicker__year-option
    self:not(:first-child, :last-child)
    .react-datepicker__year-option--selected {
    color: #3570f8;
}
.custom-time-range .react-datepicker__header .react-datepicker__header__dropdown--scroll .react-datepicker__year-dropdown-container .react-datepicker__year-option:hover {
    background-color: transparent;
}
.custom-time-range .react-datepicker__header .react-datepicker__header__dropdown--scroll .react-datepicker__year-dropdown-container .react-datepicker__year-dropdown {
    border-radius: 8px;
    border: 1px solid #eaecf0;
    background: #fff;
    padding: 0 8px;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    top: 35px;
    z-index: 3;
}
.custom-time-range
    .react-datepicker__header
    .react-datepicker__header__dropdown--scroll
    .react-datepicker__year-dropdown-container
    .react-datepicker__year-dropdown
    .react-datepicker__navigation--years {
    border-width: 2px 2px 0px 0;
    border-color: #98a7c3;
    border-radius: 1px;
    border-color: #344054;
    height: 9px;
    width: 9px;
    border-style: solid;
    margin: 15px auto 10px;
    transform: rotate(-45deg);
}
.custom-time-range
    .react-datepicker__header
    .react-datepicker__header__dropdown--scroll
    .react-datepicker__year-dropdown-container
    .react-datepicker__year-dropdown
    .react-datepicker__navigation--years:hover {
    background-color: transparent;
}
.custom-time-range
    .react-datepicker__header
    .react-datepicker__header__dropdown--scroll
    .react-datepicker__year-dropdown-container
    .react-datepicker__year-dropdown
    .react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
    transform: rotate(135deg);
    margin: 5px auto 19px;
}
.custom-time-range .react-datepicker__header .react-datepicker__header__dropdown--scroll .react-datepicker__year-read-view {
    visibility: visible !important;
}
.custom-time-range .react-datepicker__header .react-datepicker__header__dropdown--scroll .react-datepicker__year-read-view--selected-year {
    font-size: 14px;
    font-weight: 600;
    color: #344054;
}
.custom-time-range .react-datepicker__header .react-datepicker__header__dropdown--scroll .react-datepicker__year-read-view--down-arrow {
    top: 4px;
    border-color: #344054;
    border-width: 2px 2px 0px 0;
    border-radius: 1px;
}
.custom-time-range .react-datepicker__week {
    margin-bottom: 4px;
    min-height: 35px;
    display: flex;
    align-items: center;
}
.custom-time-range .react-datepicker__week .react-datepicker__day--in-range:last-child {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}
.custom-time-range .react-datepicker__week .react-datepicker__day--in-range:first-child {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}
.custom-time-range .react-datepicker__day-names {
    margin: 0 0 4px;
}
.custom-time-range .react-datepicker__day-names .react-datepicker__day-name {
    font-family: Inter;
    font-size: 12px;
    color: #344054;
    padding: 1.42px 5px;
    width: 30px;
}
@media (min-width: 1024px) {
    .custom-time-range .react-datepicker__day-names .react-datepicker__day-name {
        padding: 1.42px 5px;
        width: 30px;
    }
}
@media (min-width: 1360px) {
    .custom-time-range .react-datepicker__day-names .react-datepicker__day-name {
        padding: 3.92px 8px;
        width: 35px;
    }
}
.custom-time-range .react-datepicker__day {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    color: #344054;
    padding: 1.42px 5px;
    width: 30px;
    border-radius: 50%;
}
@media (min-width: 1024px) {
    .custom-time-range .react-datepicker__day {
        padding: 1.42px 5px;
        width: 30px !important;
        height: 30px !important;
    }
}
@media (min-width: 1360px) {
    .custom-time-range .react-datepicker__day {
        padding: 3.92px 8px;
        width: 35px !important;
        height: 35px !important;
    }
}
.custom-time-range .react-datepicker__day.react-datepicker__day--outside-month {
    color: #667085;
}
.custom-time-range .react-datepicker__day.react-datepicker__day--outside-month:empty {
    background-color: transparent !important;
}
.custom-time-range .react-datepicker__day.react-datepicker__day--outside-month:empty:after {
    background-color: transparent !important;
}
.custom-time-range .react-datepicker__day.react-datepicker__day--selected {
    color: #fff;
}
.custom-time-range .react-datepicker__day--selected {
    color: #fff;
}
.custom-time-range .react-datepicker__day--in-range {
    background-color: #f2f4f7;
    border-radius: 0;
    color: #344054;
}
.custom-time-range .react-datepicker__day--in-range.react-datepicker__day--selected {
    background-color: #f2f4f7;
}
.custom-time-range .react-datepicker__day--in-range.react-datepicker__day--selected,
.custom-time-range .react-datepicker__day--in-range.react-datepicker__day--range-end {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    position: relative;
    z-index: 2;
    color: #fff;
}
.custom-time-range .react-datepicker__day--in-range.react-datepicker__day--selected:after,
.custom-time-range .react-datepicker__day--in-range.react-datepicker__day--range-end:after {
    content: '';
    position: absolute;
    background-color: #3570f8;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%;
}

.custom-time-range .react-datepicker__day--in-range.react-datepicker__day--range-end {
    border-radius: 0;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}
.custom-time-range .react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--selected {
    border-radius: 50%;
}

.custom-time-range .react-datepicker__day--in-range.react-datepicker__day--range-end {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    position: relative;
    z-index: 2;
    /* color: #fff; */
}

.custom-time-range .react-datepicker__day--in-range.react-datepicker__day--range-end:after {
    content: '';
    position: absolute;
    background-color: #3570f8;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%;
}

.custom-time-range .react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--selected {
    border-radius: 50%;
}

.custom-time-range .react-datepicker__day--in-range.react-datepicker__day--range-end {
    border-radius: 0;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}

.custom-time-range .react-datepicker__month {
    padding: 0;
}

.react-datepicker .react-datepicker__day--today {
    position: relative;
    outline: none;
}
.react-datepicker .react-datepicker__day--today:before {
    content: '';
    height: 5px;
    width: 5px;
    background-color: #3570f8;
    border-radius: 50%;
    position: absolute;
    top: 77%;
    left: 50%;
    transform: translateX(-50%);
}
.react-datepicker .react-datepicker__day--today.react-datepicker__day--selected:before {
    background-color: #fff !important;
}
.react-datepicker .react-datepicker__day--today.react-datepicker__day--selected:before {
    background-color: #fff;
}

.overview-card {
    @apply bg-white max-h-[142px] rounded-lg border border-[#EAECF0]  cursor-pointer relative;
}

.saw-icon {
    @apply absolute right-4 top-[24px] 2xl:right-3 2xl:top-[20px] xxl:right-4 xxl:top-[26px] transform -translate-y-1/2 text-primary w-4 h-4 xxl:w-5 xxl:h-5 opacity-0;
    transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

.overview-card:hover {
    @apply shadow-lg;
}

.overview-card.active {
    @apply border-primary bg-[#F7F9FF];
}

.overview-card.active .saw-icon {
    @apply opacity-100; /* Make the icon visible when active */
}
.analytics-card {
    @apply max-h-[142px]  xxl:p-5 2xl:p-3 p-5;
}

.analytics-card .analytics-card-header {
    @apply mb-4;
}
.analytics-card .analytics-card-header2 {
    @apply mb-2.5 pt-0;
}
.analytics-card .analytics-first-text {
    @apply text-xs xlm:text-sm;
}
.analytics-card .analytics-second-text {
    @apply xxl:text-3xl text-2xl;
}
.analytics-card .analytics-second-text2 {
    @apply text-sm ml-[2px];
}
.analytics-card .analytics-icon {
    @apply w-4 h-4;
}
.analytics-card .analytics-last-text {
    @apply text-xs xlm:text-sm font-medium  tracking-normal;
}

.split-list .spacing-line {
    @apply !py-1;
}
.split-list2 .spacing-line2 {
    @apply !pb-[38px] last:!pb-0 max-2xl:!py-1;
}
/*


*/

.custom-svgs {
    /* position: relative; */
    /* margin-bottom: 1px;
     border: 0; */
    visibility: hidden;
    svg {
        &:after {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            background-color: #eaecf0;
            border-radius: inherit;
            visibility: visible;
        }
    }
}

@keyframes pulse {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}
.font-thin {
    font-weight: 100;
}
.font-extralight {
    font-weight: 200;
}

.font-light {
    font-weight: 300;
}

.font-normal {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}
.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}
.font-extrabold {
    font-weight: 800;
}

.font-black {
    font-weight: 900;
}

.pie-chart-wrapper {
    @apply w-full h-full;
}

.pie-chart-wrapper .pie-chart-wrapper-height {
    @apply w-full !h-[202px];
}

.custom-line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits to two lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar-wrapper .p-sidebar-header {
    height: 0px;
    position: relative;
}
@media (min-width: 1024px) {
    .sidebar-wrapper .p-sidebar-header {
        padding: 0px;
    }
}

.sidebar-wrapper .p-sidebar-content {
    padding-top: 8px;
    padding-left: 24px;
    padding-right: 24px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.sidebar-wrapper .p-sidebar-header::after {
    content: '';
    display: flex;
    height: 4px;
    background-color: var(--textGrey);
    width: 48px;
    opacity: 0.2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 10px;
    border-radius: 20px;
}
.sidebar-wrapper .p-sidebar-header .p-sidebar-icons {
    position: absolute;
    bottom: -39px;
    right: 16px;
    margin-top: 12px;
    z-index: 999999;
    color: #8c9cbb;
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    outline: none;
}

@media (min-width: 280px) and (max-width: 1024px) {
    .profile-sidebar-pages.sidebar-wrapper .p-sidebar-content {
        padding-left: 0px;
        padding-right: 0px;
    }
}
.profile-sidebar-pages.sidebar-wrapper .p-sidebar-content {
    padding-left: 0px;
    padding-right: 0px;
}
.profile-sidebar.profile-sidebar-pages.sidebar-wrapper .p-sidebar-content {
    padding-left: 0px;
    padding-right: 0px;
}
@media (min-width: 1024px) {
    .top-line-remove.sidebar-wrapper .p-sidebar-header::after {
        content: '';
        display: none;
    }
}

.profile-sidebar-pages.sidebar-wrapper .p-sidebar-content {
    padding-left: 0px;
    padding-right: 0px;
}
.profile-sidebar.profile-sidebar-pages.sidebar-wrapper .p-sidebar-content {
    padding-left: 0px;
    padding-right: 0px;
}

.profile-sidebar.sidebar-wrapper .p-sidebar-content {
    padding-top: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
}
.profile-sidebar {
    border-radius: 12px !important;
}
.profile-sidebar .p-sidebar-header {
    padding: 0px;
}
.profile-sidebar.sidebar-wrapper .p-sidebar-header .p-sidebar-icons:hover {
    display: none !important;
    background-color: transparent;
    border-radius: 100%;
    height: 32px;
    padding: 6px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-sidebar.sidebar-wrapper .p-sidebar-header .p-sidebar-icons {
    display: none !important;
    position: absolute;
    bottom: -23px;
    color: var(--main-text-color);
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    outline: none;
}
@media (min-width: 280px) and (max-width: 1024px) {
    .profile-sidebar .p-sidebar-close {
        display: none;
    }
    .sidebar-wrapper .p-sidebar-header .p-sidebar-icons {
        position: absolute;
        bottom: -30px !important;
        right: 16px;
        z-index: 999999;
        color: #8c9cbb;
        border-radius: 100%;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        outline: none;
    }
    .profile-sidebar.sidebar-wrapper .p-sidebar-header .p-sidebar-icons:hover {
        display: none !important;
        background-color: transparent;
        border-radius: 100%;
        height: 32px;
        padding: 6px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .profile-sidebar.sidebar-wrapper .p-sidebar-header .p-sidebar-icons {
        display: none !important;
        position: absolute;
        bottom: -23px;
        color: var(--main-text-color);
        border-radius: 100%;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        outline: none;
    }
}
.profile-sidebar.sidebar-wrapper .p-sidebar-header::after {
    content: '';
    display: none;
}
@media (min-width: 280px) and (max-width: 1024px) {
    .profile-sidebar-pages.sidebar-wrapper .p-sidebar-content {
        padding-left: 0px;
        padding-right: 0px;
    }
}
.mapboxgl-ctrl-bottom-left {
    display: none;
}

.chart-tooltip-heading {
    color: #475467 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    font-weight: 600 !important;
    padding: 8px 10px;
    border-radius: 8px 8px 0px 0px;
    background-color: #f9fafb !important;
    background: #f9fafb !important;
}
.p-dialog {
    border-radius: 12px;
}
.p-dialog-footer {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
}
.chart-tooltip-body {
    padding: 8px 20px 8px 10px;
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid #d0d5dd;
    background: #ffffff !important;
    background-color: #ffffff;
}
.apexcharts-tooltip.apexcharts-theme-light {
    border: 0px solid transparent !important;
    border-radius: 8px !important;
}

.p-sidebar-mask {
    @apply bg-[#101828] bg-opacity-30 dark:bg-opacity-80 fixed inset-0 transition delay-100 !important;
}

.truncate-custom {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}
.p-skeleton {
    position: relative;
    pointer-events: none;
    border-radius: 0px;
    background-color: #eaecf0 !important;
    visibility: visible !important;
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}
.p-skeleton:after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
    background-color: #eaecf0 !important;
    border-radius: inherit;
    visibility: visible !important;
}

@keyframes pulse {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}
.datatable-noshow.staff_service_table tbody tr:hover,
.datatable-noshow.staff_service_child_table tbody tr:hover {
    background-color: transparent;
}

.shadow-default {
    box-shadow: 0px 8px 14px -4px rgba(16, 24, 40, 0.06);
}
.shadow-default-hover:hover {
    box-shadow: 0px 8px 14px -4px rgba(16, 24, 40, 0.06);
}
.shadow-custom-sm {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.shadow-custom-md {
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
.shadow-custom-lg {
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
.apexcharts-text tspan {
    color: #475467;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}
/* .apexcharts-inner,
.apexcharts-graphical {
    width: 100% !important;
    transform: translate(0, 0) !important;
}
#chart {
    width: 100% !important;
    max-width: 100% !important;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.apexcharts-inner,
.apexcharts-graphical {
    width: 100% !important;
    max-width: 100% !important;
    transform: none !important;
} */

/* boxShadow: '0px 8px 14px -4px rgba(16, 24, 40, 0.06)',
    xs: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    sm: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    md: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
    lg: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    lgc: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    xl: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
    xxl: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)', */

.apexcharts-canvas text {
    font-family: Inter !important;
}

.apexcharts-canvas .apexcharts-legend-marker {
    border-radius: 50% !important;
    height: 7px !important;
    width: 7px !important;
    margin-right: 7px !important;
}

.apexcharts-canvas .apexcharts-legend-text {
    color: #615e83 !important;
}

.apexcharts-canvas .apexcharts-yaxis-title text {
    font-weight: 500;
}

.apexcharts-canvas .apexcharts-graphical .apexcharts-active {
    opacity: 0;
}

.graph-custom-loading {
    position: relative;
    visibility: hidden;
    pointer-events: none;
}
.graph-custom-loading:after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    background-color: #eaecf0;
    border-radius: inherit;
    visibility: visible;
}

.graph-custom-loading.loading-dark:after {
    background-color: #d1d5db;
}

.graph-custom-loading.image-loading:after {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25% 25%;
}

.graph-custom-loading.user-loading:after {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
}

.graph-custom-loading.graph-loading {
    visibility: visible;
}

.graph-custom-loading.graph-loading text {
    visibility: hidden;
}

.graph-custom-loading.graph-loading.apex-line-chart .apexcharts-canvas svg path {
    stroke: #eaecf0;
    fill: revert-layer;
}

.graph-custom-loading.graph-loading.apex-line-chart .apexcharts-inner linearGradient stop {
    stop-color: #eaecf0;
}

.graph-custom-loading.graph-loading svg {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.graph-custom-loading.graph-loading svg path {
    fill: #eaecf0;
    visibility: visible;
    filter: none;
}

.graph-custom-loading.graph-loading:after {
    background-color: transparent;
}
/* 
.apexcharts-grid,
.apexcharts-inner,
.apexcharts-xaxis {
    width: calc(100% + 60px) !important;
    min-width: calc(100% + 60px) !important;
    transform: translate(37.40625, 60) !important;
} */
