.p-selectable-row {
    border-bottom: 1px solid var(--gray-100);
    min-height: 64px;
    height: 64px;
}
.p-selectable-row:hover {
    transition-duration: 0.5s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #f2f4f7;
}
.p-datatable .p-datatable-tbody > tr > td {
    padding: 10.5px 8px;
    min-height: 64px;
}
.detail-data-main {
    @apply py-6 border-b border-lineColor flex items-start gap-8;
}
.detail-data-main .title-block {
    @apply w-[280px] min-w-[280px] max-w-[280px] xlm:w-[310px] xlm:min-w-[310px] xlm:max-w-[310px];
}
.detail-data-main .title-block h3 {
    @apply text-sm font-semibold text-gray-700 mb-1.5 capitalize;
}
.detail-data-main .title-block p {
    @apply text-xs leading-[18px] text-gray-600 font-normal;
}
.detail-data-main .data-wrap-block {
    @apply flex-1;
}
.p-link.p-highlight {
    @apply !h-10 !w-10 !bg-gray-50 !text-gray-800 !text-sm !font-medium !rounded-md;
}
.p-paginator-page {
    @apply !h-10 !w-10 !text-sm !font-medium !rounded-md !text-gray-600;
}
.rang-slider-custom .p-slider {
    @apply bg-[#EAECF0] h-2 rounded-lg;
}
.p-slider-range {
    @apply rounded-lg bg-[#3570F8];
}
.p-slider-handle {
    @apply bg-white border-2 border-[#3570F8] h-6 w-6 -mt-3;
}
.custom-datepicker .react-datepicker__input-container {
    @apply !border !border-gray-300 shadow-InputAndButton !text-base !font-normal placeholder:text-base placeholder:font-normal placeholder:text-gray-500 rounded-lg h-[36px] w-full relative overflow-hidden mt-2;
}
.custom-datepicker .date-field-block .icon {
    opacity: 1 !important;
}
.info-title-text {
    @apply text-sm font-semibold text-gray-900 mt-4 mb-3;
}
.datatable-custom .p-datatable {
    @apply border-gray-200 overflow-hidden;
}

.datatable-custom tbody tr {
    @apply min-h-[64px] border-b  border-gray-200 h-[64px];
}

.datatable-custom tbody tr:last-child {
    @apply border-0;
}
/* .analytics .datatable-custom tbody tr:last-child {
    @apply border-b;
} */
.datatable-custom .p-datatable .p-datatable-tbody > tr > td:last-child {
    width: 130px;
}
.datatable-custom-client.datatable-custom .p-datatable .p-datatable-tbody > tr > td:last-child {
    width: 70px !important;
    text-align: center;
}
.search-input .form-control-md {
    @apply !h-[34px] pl-0;
}
.sidebar-search-input .form-control-md {
    @apply !h-[34px] pl-0;
}
.py-tab-btn-view {
    @apply bg-gray-50 border border-borderSecondary p-[3px] rounded-[10px] gap-1;
}
.py-tab-btn-view .py-tab-link {
    @apply w-full max-w-[170px] rounded-md text-sm font-semibold text-gray-500 px-3 py-2;
}
.py-tab-btn-view .py-tab-link.active {
    @apply bg-white text-gray-700 font-semibold text-sm shadow;
}

.staff_service_table tbody tr {
    @apply border-b border-gray-200 min-h-[64px] h-[64px];
}

.staff_service_table .p-datatable .p-datatable-tbody > tr > td:first-child {
    padding-left: 20px;
    height: 64px;
}
.dashboard-table .p-datatable .p-datatable-tbody > tr > td:first-child {
    padding-left: 20px;
    height: 64px;
}
.datatable-custom .p-datatable .p-datatable-tbody > tr > td:first-child {
    padding-left: 20px;
    height: 64px;
}
.datatable-noshow.datatable-custom tbody tr {
    @apply !h-[100%];
}
.datatable-noshow.staff_service_table tbody tr {
    @apply !h-[100%];
}
.sales-table .datatable-noshow.staff_service_table tbody tr {
    @apply !h-[90%];
}
.dashboard-table .p-datatable-wrapper::-webkit-scrollbar,
.custom-scroll::-webkit-scrollbar {
    width: 6px;
    height: 5px !important;
}

.dashboard-table .p-datatable-wrapper::-webkit-scrollbar-track,
.custom-scroll::-webkit-scrollbar-track {
    background: #ebebeb;
}
.dashboard-table .p-datatable-wrapper::-webkit-scrollbar-thumb,
.custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #c4c4c499;
    height: 5px;
}
.scrollbar-width .p-datatable-wrapper::-webkit-scrollbar,
.custom-scroll::-webkit-scrollbar {
    width: 6px !important;
    height: 5px !important;
}

.scrollbar-width .p-datatable-wrapper::-webkit-scrollbar-track,
.custom-scroll::-webkit-scrollbar-track {
    background: #ebebeb;
}
.scrollbar-width .p-datatable-wrapper::-webkit-scrollbar-thumb,
.custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    background: #c4c4c499;
    height: 5px !important;
}
.dashboard-table .custom-height {
    min-height: 460px;
    height: 460px;
}
.dashboard-table .datatable-noshow.datatable-custom tbody tr:last-child {
    @apply border-none;
}
.dashboard-table .datatable-custom tbody tr:last-child {
    @apply border-b;
}
.payment_split_table.datatable-custom tbody tr:last-child {
    @apply max-xl:!border-b;
}

.staff_service_table tbody .p-datatable-row-expansion > td {
    @apply !p-0;
}

.staff_service_table tbody .p-datatable-row-expansion td .p-datatable-tbody tr {
    @apply !bg-gray-25;
}
.staff_service_table tbody .p-datatable-row-expansion td .p-datatable-tbody tr:last-child {
    border: 0px solid transparent !important;
}
.staff_service_table .p-datatable-wrapper {
    @apply overflow-hidden;
}
.staff_service_child_table tbody tr {
    @apply border-b border-gray-200 min-h-[64px] h-[64px] max-h-[64px];
}

.staff_service_child_table .p-datatable .p-datatable-tbody > tr > td:first-child {
    padding-left: 16px;
    height: 64px;
}
.datatable-noshow.staff_service_child_table tbody tr {
    @apply !h-[100%];
}
.staff_service_child_table tbody tr:last-child {
    @apply border-b;
}

.staff_service_child_table tbody .p-datatable-row-expansion > td {
    @apply !p-0;
}

.staff_service_child_table tbody .p-datatable-row-expansion td .p-datatable-tbody tr {
    @apply !bg-gray-50;
}

.staff_service_child_table .p-datatable-wrapper {
    @apply overflow-auto;
}
.table_text {
    @apply font-normal text-gray-600 leading-[18px] text-xs;
}
.commission-payouts .p-datatable .p-datatable-tbody > tr > td:first-child {
    padding-left: 20px;
}
.bg-banner {
    background-image: url('../img/Backgroundpattern.svg');
    background-repeat: no-repeat;
    background-size: auto;
    width: 100%;
}
.select-box-custom .fwpo_select__indicator svg,
.select-box-custom .fwpo_select__indicators svg,
.fwpo_select__clear-indicator svg {
    color: #667085;
    width: 16px;
}

.select_border .fwpo_select__control {
    height: fit-content !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.pay-tab-btn-view {
    @apply bg-white  flex;
}
.pay-tab-btn-view .pay-tab-link {
    @apply w-full min-w-max h-[28px] border border-gray-200 rounded-[6px] text-xs font-semibold text-gray-600 px-2 flex justify-center items-center hover:border-[#5279F0] hover:text-[#1642C5];
}
.pay-tab-btn-view .pay-tab-link.active {
    @apply bg-white border border-[#5279F0] text-[#1642C5];
}

.payment-tab-btn-view {
    @apply bg-white  flex;
}
.payment-tab-btn-view .payment-tab-link {
    @apply w-full min-w-[70px] h-[30px] border border-[#EAECF0]  rounded-[6px]  text-xs font-medium text-gray-500 px-1.5 py-1 flex justify-center items-center;
}
.payment-tab-btn-view .payment-tab-link.active {
    @apply bg-white border border-[#7191EF] text-[#143CB3];
}
.input-with-button {
    display: flex;
    align-items: stretch;
    width: 100%;
}
